/** @jsxImportSource @emotion/react */
import { Country } from "../entities/Country";
import CountrySelector from "./CountrySelector";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "../constants/breakpoints";

interface ICountrySelectorListProps {
  countries: Country[];
}

const styles = {
  list: css`
    padding: 0;
    columns: 1;
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      padding-right: 8px;
      columns: 2;
      max-width: 592px;
      margin: 0 0;
    }
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      columns: 1;
      max-width: auto;
    }
  `,
};

const CountrySelectorList = ({ countries }: ICountrySelectorListProps) => {
  return (
    <div css={styles.list}>
      {countries.map((country) => (
        <CountrySelector country={country} key={country.iso} />
      ))}
    </div>
  );
};
export default CountrySelectorList;
