import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    {...props}
  >
    <path
      fill="#2A7390"
      d="M2 0h10c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V2C0 .906.875 0 2 0Zm4.25 9.75c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75v-2h2A.74.74 0 0 0 10.5 7a.76.76 0 0 0-.75-.75h-2v-2A.76.76 0 0 0 7 3.5a.74.74 0 0 0-.75.75v2h-2A.74.74 0 0 0 3.5 7c0 .438.313.75.75.75h2v2Z"
    />
  </svg>
);
export default SvgComponent;
