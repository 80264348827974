import { Country } from "../entities/Country";
import { createContext, useContext } from "react";

export type CountryHighlightedState = {
  highlighted: Country | null;
  setHighlighted: (c: Country | null) => void;
};

export const HighlightedCountryContext = createContext<CountryHighlightedState>(
  {
    highlighted: null,
    setHighlighted: () => {},
  },
);
export const useHighlightedCountriesContext = () =>
  useContext(HighlightedCountryContext);
