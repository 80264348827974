/** @jsxImportSource @emotion/react */
import * as React from "react";
import { Country } from "../entities/Country";
import { useSelectedCountriesContext } from "../context/selectedCountries";
import { COLORS } from "../constants/colours";
import { Flag } from "./flags/Flag";
import { css } from "@emotion/react";
import IconDeselect from "./icons/IconDeselect";
import { toggleCountry } from "../utils/toggle";
import { BREAKPOINTS } from "../constants/breakpoints";
import { useHighlightedCountriesContext } from "../context/highlightedCountry";

interface ICountrySelectorProps {
  country: Country;
}

const styles = {
  countryTab: css`
    display: flex;
    width: 100%;
  `,
  flag: css`
    flex-grow: 0;
    flex-shrink: 1;
    align-self: center;
    height: 24px;
  `,
  label: css`
    color: ${COLORS.black},
    font-family: Source Sans Pro;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    margin-left: 12px;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    text-align: left;
    line-height: 24px;
    height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      font-size: 18px;
    }
  `,
  labelFull: css`
    display: none;
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      display: flex;
    }
  `,
  labelIso: css`
    text-transform: uppercase;
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      display: none;
    }
  `,
  deselectWrapper: css`
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    height: 24px;
    justify-content: center;
    align-items: center;
  `,
  deselect: css`
    margin-top: 3px;
    cursor: pointer;
  `,
  countryHighlighted: css``,
  countryNotHighlighted: css``,
  contentHighlighted: css`
    display: none;
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      display: flex;
    }
  `,
  contentNotHighlighted: css`
    display: none;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      display: flex;
    }
  `,
};

const CountryTab = ({ country }: ICountrySelectorProps) => {
  const { selected, setSelected } = useSelectedCountriesContext();
  const { highlighted, setHighlighted } = useHighlightedCountriesContext();

  const countryStyles =
    country === highlighted
      ? [styles.countryTab, styles.countryHighlighted]
      : [styles.countryTab, styles.countryNotHighlighted];

  const contentHighlightStyle =
    country === highlighted
      ? styles.contentHighlighted
      : styles.contentNotHighlighted;

  return (
    <div css={countryStyles}>
      <div css={styles.flag} onClick={() => setHighlighted(country)}>
        <Flag identifier={country.iso} />
      </div>
      <div
        css={[
          styles.label,
          highlighted === country ? null : contentHighlightStyle,
        ]}
        onClick={() => setHighlighted(country)}
      >
        {selected.length <= 2 ? (
          <div>{country.name}</div>
        ) : (
          <div>
            <span css={styles.labelFull}>{country.name}</span>
            <span css={styles.labelIso}>{country.iso}</span>
          </div>
        )}
      </div>
      {selected.includes(country) && (
        <div css={[styles.deselectWrapper, contentHighlightStyle]}>
          <IconDeselect
            css={styles.deselect}
            onClick={() => toggleCountry({ selected, setSelected, country })}
          />
        </div>
      )}
    </div>
  );
};
export default CountryTab;
