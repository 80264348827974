/** @jsxImportSource @emotion/react */
import CountrySelectorList from "./CountrySelectorList";
import React from "react";
import { css } from "@emotion/react";
import { COLORS } from "../constants/colours";
import { useNavigate } from "react-router-dom";
import { useCountriesContext } from "../context/countries";
import { useSelectedCountriesContext } from "../context/selectedCountries";
import { BREAKPOINTS } from "../constants/breakpoints";
import { MAX_SELECTIONS } from "../utils/toggle";

const styles = {
  controls: css`
    background-color: ${COLORS.brandSecondaryRusiRoyalBlue};
    z-index: 900;
    border-radius: 4;
    padding: 8px;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      position: absolute;
      top: 80px;
      left: 0;
    }
  `,
  controlsInner: css`
    overflow-x: hidden;
    overflow-y: scroll;

    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      max-height: 468px;

      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        background-color: #041b31;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        width: 6px;
        background-color: ${COLORS.white};
      }
    }
  `,
  compareButton: css`
    margin: 16px auto 0 auto;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #c9c9c9;
    background: #c9c9c9;
    color: ${COLORS.white};
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    max-width: 264px;
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      max-width: 108px;
    }
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      max-width: 100%;
    }
  `,
  compareButtonReady: css`
    background-color: ${COLORS.brandSecondaryRusiLightBlueTag};
    border-color: ${COLORS.brandSecondaryRusiLightBlueTag};
  `,
  compareCount: css`
    display: inline-block;
    background-color: ${COLORS.white};
    width: 23px;
    height: 23px;
    border-radius: 50px;
    color: ${COLORS.black};
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
  `,
  compareCountMax: css`
    display: inline-block;
    background-color: ${COLORS.white};
    height: 23px;
    border-radius: 50px;
    padding: 0 5px;
    color: ${COLORS.black};
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
  `,
};

export const MapControls = () => {
  const nav = useNavigate();
  const countries = useCountriesContext();
  const { selected } = useSelectedCountriesContext();

  return (
    <div css={styles.controls}>
      <div css={styles.controlsInner}>
        <CountrySelectorList countries={countries} />
      </div>
      <div
        css={[
          styles.compareButton,
          selected.length > 0 && styles.compareButtonReady,
        ]}
        onClick={() => {
          if (selected.length > 0) {
            nav("compare");
          }
        }}
      >
        Compare{" "}
        <div
          css={
            selected.length >= MAX_SELECTIONS
              ? styles.compareCountMax
              : styles.compareCount
          }
        >
          {selected.length}{" "}
          {selected.length >= MAX_SELECTIONS ? <span>(max)</span> : null}
        </div>
      </div>
    </div>
  );
};
