import { Country } from "../entities/Country";
import { createContext, useContext } from "react";

export type CountryHoveredState = {
  hovered: Country | null;
  setHovered: (c: Country | null) => void;
};

export const HoveredCountriesContext = createContext<CountryHoveredState>({
  hovered: null,
  setHovered: () => {},
});
export const useHoveredCountriesContext = () =>
  useContext(HoveredCountriesContext);
