import FlagUnitedKingdom from "./FlagUnitedKingdom";
import FlagAustralia from "./FlagAustralia";
import FlagBangladesh from "./FlagBangladesh";
import FlagBrunei from "./FlagBrunei";
import FlagCambodia from "./FlagCambodia";
import FlagChina from "./FlagChina";
import FlagIndonesia from "./FlagIndonesia";
import FlagIndia from "./FlagIndia";
import FlagJapan from "./FlagJapan";
import FlagLaos from "./FlagLaos";
import FlagMyanmar from "./FlagMyanmar";
import FlagMalaysia from "./FlagMalaysia";
import FlagNepal from "./FlagNepal";
import FlagPhilippines from "./FlagPhilippines";
import FlagBhutan from "./FlagBhutan";
import FlagSingapore from "./FlagSingapore";
import FlagThailand from "./FlagThailand";
import FlagVietnam from "./FlagVietnam";
import FlagSouthKorea from "./FlagSouthKorea";

interface IFlagProps {
  identifier: string;
  width?: number;
  height?: number;
}

const FLAG_WIDTH = 32;
const FLAG_HEIGHT = 24;

export const Flag = ({
  identifier,
  width = FLAG_WIDTH,
  height = FLAG_HEIGHT,
}: IFlagProps) => {
  switch (identifier?.toUpperCase()) {
    case "AUS":
      return <FlagAustralia width={width} height={height} />;

    case "BGD":
      return <FlagBangladesh width={width} height={height} />;

    case "BTN":
      return <FlagBhutan width={width} height={height} />;

    case "BRN":
      return <FlagBrunei width={width} height={height} />;

    case "KHM":
      return <FlagCambodia width={width} height={height} />;

    case "CHN":
      return <FlagChina width={width} height={height} />;

    case "IND":
      return <FlagIndia width={width} height={height} />;

    case "IDN":
      return <FlagIndonesia width={width} height={height} />;

    case "JPN":
      return <FlagJapan width={width} height={height} />;

    case "LAO":
      return <FlagLaos width={width} height={height} />;

    case "MYS":
      return <FlagMalaysia width={width} height={height} />;

    case "MMR":
      return <FlagMyanmar width={width} height={height} />;

    case "NPL":
      return <FlagNepal width={width} height={height} />;

    case "PHL":
      return <FlagPhilippines width={width} height={height} />;

    case "GBR":
      return <FlagUnitedKingdom width={width} height={height} />;

    case "SGP":
      return <FlagSingapore width={width} height={height} />;

    case "THA":
      return <FlagThailand width={width} height={height} />;

    case "VNM":
      return <FlagVietnam width={width} height={height} />;

    case "KOR":
      return <FlagSouthKorea width={width} height={height} />;

    default:
      return <div></div>;
  }
};
