/** @jsxImportSource @emotion/react */
import { Country } from "../entities/Country";
import { css } from "@emotion/react";
import { COLORS } from "../constants/colours";
import { Flag } from "./flags/Flag";
import IconSelect from "./icons/IconSelect";
import { useSelectedCountriesContext } from "../context/selectedCountries";
import { toggleCountry } from "../utils/toggle";
import IconDeselect from "./icons/IconDeselectBlack";

interface IPopupComponentProps {
  country: Country;
}

const styles = {
  base: css({
    marginLeft: "43px",
    width: "19px",
    height: "19px",
    borderRadius: 50,
    backgroundColor: COLORS.white,
  }),
  pole: css({
    marginLeft: "50px",
    width: "4px",
    height: "30px",
    backgroundColor: COLORS.white,
  }),
  main: css({
    marginLeft: "50px",
    padding: "12px",
    backgroundColor: COLORS.white,
    borderRadius: "0px 4px 4px 0px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "normal",
    alignItems: "normal",
    alignContent: "normal",
    gap: "12px",
  }),
  flag: css({
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "auto",
    height: "24px",
  }),
  name: css({
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "auto",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    height: "24px",
    lineHeight: "24px",
  }),
  control: css({
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "auto",
    height: "24px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  }),
};

export const PopupComponent = ({ country }: IPopupComponentProps) => {
  const { selected, setSelected } = useSelectedCountriesContext();

  return (
    <div>
      <div css={styles.main}>
        <div css={styles.flag}>
          <Flag identifier={country.iso} />
        </div>
        <div css={styles.name}>{country.name}</div>
        <div
          css={styles.control}
          onClick={() => toggleCountry({ selected, setSelected, country })}
        >
          {selected.includes(country) ? <IconDeselect /> : <IconSelect />}
        </div>
      </div>
      <div css={styles.pole}></div>
      <div css={styles.base}></div>
    </div>
  );
};
