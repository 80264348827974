import L from "leaflet";

export const normalIcon = L.divIcon({
  html: `
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <circle cx="7.5" cy="7.5" r="5.5" fill="#2A7390" stroke="white" stroke-width="4"/>
      </svg>
    `,
  className: "",
  iconSize: [15, 15],
});

export const hoverIcon = L.divIcon({
  html: `
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <circle cx="9.5" cy="9.5" r="7.5" fill="#F5AD2D" stroke="white" stroke-width="4"/>
      </svg>
    `,
  className: "",
  iconSize: [19, 19],
});
