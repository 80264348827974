import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    {...props}
  >
    <path
      fill="#287D9F"
      d="M2 0h10c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V2C0 .906.875 0 2 0Zm2.75 6.25A.74.74 0 0 0 4 7c0 .438.313.75.75.75h4.5A.74.74 0 0 0 10 7a.76.76 0 0 0-.75-.75h-4.5Z"
    />
  </svg>
);
export default SvgComponent;
