import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#032EA1" d="M0 0h32v24H0V0Z" />
      <path fill="#E00025" d="M0 6h32v12H0V6Z" />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M12.625 11.309h6.828v2.628h-6.828v-2.628Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M19.105 12.754h.21v.99h-.21v-.99Zm-6.48-.486h6.828v.3h-6.828v-.3Zm0-.42h6.828v.276h-6.828v-.276Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.08}
        d="M12.625 11.441h6.828v.258h-6.828v-.258Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M14.424 12.754h.216v.99h-.216v-.99Zm2.94 0h.216v.99h-.216v-.99Zm-4.68 0h.21v.99h-.21v-.99Zm.42 0h.21v.99h-.21v-.99Zm.45 0h.21v.99h-.21v-.99Zm.45 0h.21v.99h-.21v-.99Zm3.768 0h.216v.99h-.216v-.99Zm.45 0h.216v.99h-.216v-.99Zm.45 0h.216v.99h-.216v-.99Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M9.953 14.068a.558.558 0 0 0 .264-.318h11.586c.044.136.139.25.264.318H9.953Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M15.862 7.307s-.006-.246.138-.252c.138 0 .132.252.132.252h-.27Zm-.75 3.27v-.33c0-.168-.168-.18-.168-.3 0 0-.024-.18.024-.264.066.24.18.198.18.096 0-.084-.06-.168-.198-.378-.048-.066-.018-.276.042-.354.024.18.054.27.132.27.048 0 .084-.03.084-.12 0-.12-.078-.18-.12-.288a.3.3 0 0 1 .066-.318c.03.18.024.252.102.252.162-.054 0-.288-.036-.348-.036-.066.06-.204.06-.204.048.162.06.174.12.156.072-.018.06-.12-.024-.204-.054-.06-.048-.138.012-.198.06.114.132.108.138.036l-.048-.264h1.044l-.054.258c-.012.072.084.09.144-.03.06.06.066.144.012.198-.084.084-.096.186-.024.204.06.018.072 0 .12-.156 0 0 .09.09.06.204-.036.06-.198.3-.036.348.078 0 .072-.072.102-.252a.3.3 0 0 1 .06.318c-.036.108-.12.168-.12.288 0 .09.042.12.09.12.078 0 .108-.084.132-.27.06.078.09.288.042.36-.138.204-.204.288-.204.372 0 .102.12.144.18-.096.054.084.03.264.03.264 0 .12-.162.132-.168.3v.33h-1.776Zm.432-2.76-.024-.186h.954l-.024.186h-.906Zm.06-.192-.012-.15h.81l-.018.15h-.78Zm.138-.156-.018-.156h.54l-.006.156h-.516Zm1.98 6.6c-.12-.042-.3-.174-.3-.3V12.31l.156-.204h-3.156l.15.204v1.458c0 .126-.12.258-.24.3h3.39Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M14.973 12.754h.216v.99h-.216v-.99Zm1.824 0h.216v.99h-.216v-.99Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M14.361 10.45v1.655h3.24V10.45a.25.25 0 0 0-.155.168v.708h-2.923v-.708s-.035-.12-.168-.168h.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M17.157 14.07c-.108-.042-.336-.174-.336-.3v-1.632c.024-.09.144-.144.222-.204h-2.142c.102.06.216.102.258.204v1.632c0 .126-.18.258-.288.3h2.286Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M17.445 11.95v-1.176h-.294v-.114h-2.328v.12h-.3v1.17h2.922Zm-.744 2.118c-.108-.042-.258-.174-.258-.3v-1.392l.084-.126h-1.062l.09.12v1.398c0 .126-.156.258-.258.3h1.404Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M15.723 12.25h.54v1.818h-.54V12.25Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M16.546 11.85c0-.12.348-.127.528-.229h-2.16c.18.102.522.108.522.228l.072.234.9.036.138-.27Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M16.964 10.578c0-.294.012-.402.102-.402v.924c-.222.084-.378.36-.378.36h-1.392s-.156-.276-.378-.36v-.93c.108 0 .108.12.108.402l1.938.006Zm.102-.12a.35.35 0 0 1 .294-.372v.3c-.114-.006-.168.096-.168.24 0 .15.09.15.09.15v.852h-.216v-1.17Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M14.919 10.458c0-.336-.294-.372-.294-.372v.3c.114-.006.168.096.168.24 0 .15-.09.15-.09.15v.852h.216v-1.17Z"
      />
      <path
        stroke="#000"
        strokeWidth={0.96}
        d="M15.49 8.133h1.032m-1.152.324h1.26m-1.38.39h1.494m-1.62.474h1.77m-1.812.54h1.824"
      />
      <path
        stroke="#000"
        strokeWidth={1.2}
        d="M14.531 11.637h2.916m-1.992 0h1.08v.396h-1.08v-.396Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M15.32 10.75c.18.215.156.581.156.797h1.044c0-.216-.024-.582.156-.798H15.32Zm.582-2.46-.144-.079v-.21c.06.018.12.024.132.12.018-.138.06-.126.114-.18.06.054.09.042.114.18 0-.096.072-.102.126-.12v.21l-.138.072-.204.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="m15.892 8.633-.24-.168v-.186c.09.018.18.03.192.132.024-.15.078-.222.162-.282.078.06.132.132.162.282.006-.102.102-.114.18-.132v.192l-.234.162h-.222Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="m15.873 9.166-.27-.24v-.282c.096.024.204.036.216.186.03-.21.09-.324.18-.408.096.084.156.198.192.408.012-.15.12-.162.216-.186v.282l-.276.24h-.258Zm.504.318-.24.342h-.282l-.246-.342h.768Zm-.912.57c.12.066.168.204.18.456h.696c.012-.252.06-.39.18-.456h-1.056Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M16.533 9.48v-.336a.312.312 0 0 0-.228.198c0-.12-.15-.378-.312-.51-.162.144-.318.384-.312.504-.03-.09-.108-.162-.228-.192v.342l1.08-.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M16.582 10.054v-.336c-.126.036-.21.102-.246.198 0-.12-.162-.378-.342-.51-.18.15-.348.384-.342.51-.03-.09-.12-.162-.246-.198v.342l1.176-.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M16.728 10.755v-.396a.576.576 0 0 0-.306.228c0-.21-.24-.54-.426-.642-.192.108-.426.444-.426.642a.582.582 0 0 0-.312-.228v.396h1.47Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M16.644 11.639v-.408c-.144.054-.18.186-.228.282.018-.414-.228-.852-.42-.966-.192.114-.444.564-.42.96-.048-.084-.09-.222-.228-.276v.402l1.296.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M16.545 12.25v-.407c-.15.036-.156.09-.204.18.018-.246-.156-.528-.348-.636a.76.76 0 0 0-.348.636c-.048-.09-.054-.144-.204-.18v.408h1.104ZM10.221 13.75v-1.225c-.042-.156-.18-.3-.276-.324v-1.08l.222.12.258 1.134v1.38l-.204-.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M10.221 13.757v-1.23c-.042-.156-.18-.3-.276-.324V11.05c.15 0 .222.192.222.192l.258 1.134v1.374l-.204.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M10.059 14.065c.102-.042.252-.174.252-.3V12.23l-.072-.12h2.622l-.102.12v1.536a.36.36 0 0 0 .204.3h-2.904Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M12.43 14.068c-.102-.043-.252-.175-.252-.3v-1.459l.216-.204h-1.776l.216.204v1.459c0 .125-.15.257-.252.3h1.848Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M12.184 14.068c-.102-.042-.258-.174-.258-.3v-1.32l.144-.198h-1.128l.144.198v1.32c0 .126-.15.258-.258.3h1.356Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M11.24 12.25h.534v1.824h-.54l.006-1.824Z"
      />
      <path
        fill="#fff"
        d="M10.502 12.755h.216v.99h-.216v-.99Zm1.848 0h.21v.99h-.216l.006-.99Zm-2.034-1.669h.264v1.02h-.264v-1.02Zm0-.191h.258v.191h-.258v-.191Zm2.136.414h.366v.78h-.366v-.78Z"
      />
      <path
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M10.316 11.086h.264v1.02h-.264v-1.02Zm0 0h.258v-.191h-.258v.191Zm.186 1.668h.216v.99h-.216v-.99Zm1.848 0h.21v.99h-.216l.006-.99Zm.102-1.445h.366v.78h-.366v-.78Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M10.574 12.093v-1.74c.072 0 .084.258.252.258.09 0 .084-.108.03-.192-.042-.078-.096-.18-.024-.378.054.15.186.198.162.108-.042-.162-.168-.192-.072-.438.03.204.162.198.132.078-.036-.138-.114-.198-.018-.39.054.222.12.21.12.072 0-.204 0-.42.252-.498 0 0 .018-.18.114-.18.09 0 .108.18.108.18.258.078.252.3.252.498 0 .138.066.15.12-.072.096.192.018.252-.018.39-.03.12.102.126.132-.078.096.246-.03.276-.072.438-.024.09.108.042.162-.108.072.198.018.3-.024.378-.048.084-.06.192.03.192.168 0 .18-.252.252-.252v1.734h-1.89Zm-.414-1.314v1.326h.15v-1.326c-.054-.03-.102-.03-.15 0Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M10.172 11.638c.186.096.372.21.42.468h-.42v-.468Zm2.592-.396v.864h.12v-.864c-.036-.018-.09-.024-.12 0Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M12.883 11.637c-.185.09-.371.198-.42.462h.42v-.462Zm-1.235-2.022.107-.09v-.12c-.036 0-.06.018-.09.06a.299.299 0 0 0-.15-.18.3.3 0 0 0-.155.174c-.03-.042-.048-.048-.09-.06v.12l.107.096h.27Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="m11.707 9.907.048-.114v-.15c-.036 0-.06.018-.09.06a.299.299 0 0 0-.15-.18.3.3 0 0 0-.155.174c-.03-.042-.048-.048-.09-.054v.15l.048.114h.39Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="m11.722 10.303.156-.198v-.192c-.06 0-.09.03-.132.096-.042-.138-.12-.162-.228-.228-.114.06-.192.09-.228.222-.048-.066-.078-.084-.138-.09v.192l.162.198h.408Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M11.762 10.695s.192-.162.198-.252v-.21c-.072.006-.138.024-.192.114-.048-.174-.12-.222-.252-.3-.138.078-.21.126-.252.3-.06-.09-.12-.108-.198-.12v.216c.05.095.118.18.198.252h.498Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M11.84 11.416s.264-.282.27-.396v-.324c-.096.012-.192.078-.264.216-.06-.27-.156-.456-.33-.588-.18.132-.276.318-.336.588-.072-.138-.162-.204-.258-.216v.324c.018.114.264.396.264.396h.654Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M11.84 11.84s.222-.223.27-.319v-.324c-.096.013-.192.079-.264.21a.842.842 0 0 0-.33-.552c-.18.133-.276.282-.336.553-.072-.133-.162-.198-.258-.21v.323c.06.096.264.318.264.318h.654Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M11.932 12.093c-.06-.282-.12-.492-.426-.702-.312.21-.366.42-.432.702h.858Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M12.092 12.248v-.409c-.144.06-.27.138-.318.229-.048-.229-.15-.325-.276-.463-.126.138-.21.24-.264.463-.048-.09-.174-.175-.312-.229v.409h1.17ZM21.783 13.75v-1.225c.042-.156.18-.3.276-.324v-1.08l-.222.12-.258 1.134v1.38l.204-.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M21.783 13.757v-1.23c.042-.156.18-.3.276-.324V11.05c-.15 0-.222.192-.222.192l-.258 1.134v1.374l.204.006Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M21.945 14.065c-.102-.042-.252-.174-.252-.3V12.23l.072-.12h-2.622l.102.12v1.536a.36.36 0 0 1-.204.3h2.904Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M19.574 14.068c.102-.043.252-.175.252-.3v-1.459l-.216-.204h1.776l-.216.204v1.459c0 .125.15.257.252.3h-1.848Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M19.82 14.068c.102-.042.258-.174.258-.3v-1.32l-.144-.198h1.128l-.144.198v1.32c0 .126.15.258.258.3H19.82Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M20.764 12.25h-.534v1.824h.54l-.006-1.824Z"
      />
      <path
        fill="#fff"
        d="M21.502 12.755h-.216v.99h.215v-.99Zm-1.848 0h-.21v.99h.216l-.006-.99Zm2.034-1.669h-.264v1.02h.264v-1.02Zm0-.191h-.258v.191h.258v-.191Zm-2.136.414h-.366v.78h.366v-.78Z"
      />
      <path
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth={1.2}
        d="M21.688 11.086h-.264v1.02h.264v-1.02Zm0 0h-.258v-.191h.258v.191Zm-.186 1.668h-.216v.99h.215v-.99Zm-1.848 0h-.21v.99h.216l-.006-.99Zm-.102-1.445h-.366v.78h.366v-.78Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M21.43 12.093v-1.74c-.072 0-.084.258-.252.258-.09 0-.084-.108-.03-.192.042-.078.096-.18.024-.378-.054.15-.186.198-.162.108.042-.162.168-.192.072-.438-.03.204-.162.198-.132.078.036-.138.114-.198.018-.39-.054.222-.12.21-.12.072 0-.204 0-.42-.252-.498 0 0-.018-.18-.114-.18-.09 0-.108.18-.108.18-.258.078-.252.3-.252.498 0 .138-.066.15-.12-.072-.096.192-.018.252.018.39.03.12-.102.126-.132-.078-.096.246.03.276.072.438.024.09-.108.042-.162-.108-.072.198-.018.3.024.378.048.084.06.192-.03.192-.168 0-.18-.252-.252-.252v1.734h1.89Zm.414-1.314v1.326h-.15v-1.326c.054-.03.102-.03.15 0Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M21.832 11.638c-.186.096-.372.21-.42.468h.42v-.468Zm-2.592-.396v.864h-.12v-.864c.036-.018.09-.024.12 0Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M19.12 11.637c.186.09.372.198.42.462h-.42v-.462Zm1.236-2.022-.108-.09v-.12c.036 0 .06.018.09.06a.299.299 0 0 1 .15-.18.3.3 0 0 1 .156.174c.03-.042.048-.048.09-.06v.12l-.108.096h-.27Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="m20.296 9.907-.048-.114v-.15c.036 0 .06.018.09.06a.299.299 0 0 1 .15-.18.3.3 0 0 1 .156.174c.03-.042.048-.048.09-.054v.15l-.048.114h-.39Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="m20.282 10.303-.156-.198v-.192c.06 0 .09.03.132.096.042-.138.12-.162.228-.228.114.06.192.09.228.222.048-.066.078-.084.138-.09v.192l-.162.198h-.408Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M20.241 10.695s-.192-.162-.197-.252v-.21c.072.006.137.024.191.114.049-.174.12-.222.253-.3.137.078.21.126.252.3.06-.09.12-.108.198-.12v.216a.902.902 0 0 1-.198.252h-.498Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M20.164 11.416s-.264-.282-.27-.396v-.324c.096.012.192.078.264.216.06-.27.156-.456.33-.588.18.132.276.318.336.588.072-.138.162-.204.258-.216v.324c-.018.114-.264.396-.264.396h-.654Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M20.164 11.84s-.222-.223-.27-.319v-.324c.096.013.192.079.264.21a.842.842 0 0 1 .33-.552c.18.133.276.282.336.553.072-.133.162-.198.258-.21v.323c-.06.096-.264.318-.264.318h-.654Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M20.072 12.093c.06-.282.12-.492.426-.702.312.21.366.42.432.702h-.858Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M19.912 12.248v-.409c.144.06.27.138.318.229.048-.229.15-.325.276-.463.126.138.21.24.264.463.048-.09.174-.175.312-.229v.409h-1.17ZM8.644 15.558h14.718v.75H8.644v-.75Zm-.39.75h15.498v.75H8.254v-.75Zm1.164-1.878h13.164v.486H9.418v-.486Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M9.035 14.917H22.98v.636H9.035v-.636Zm.612-.858h12.72v.372H9.647v-.372Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M11.027 14.059h.96v3h-.96v-3Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M11.24 14.059h.534v3h-.54l.006-3Zm4.26 0h.96v3h-.96v-3Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M15.723 14.059h.54v3h-.54v-3Zm4.29 0h.96v3h-.96v-3Z"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={1.2}
        d="M20.223 14.059h.54v3h-.54v-3Z"
      />
      <path
        stroke="#000"
        strokeWidth={1.2}
        d="M10.223 13.75h11.58M11.23 16.678h.552m-.552-.378h.552m-.552-.372h.552m-.552-.378h.552m-.552-.372h.552m-.552-.378h.552m-.552-.372h.552m3.948 2.25h.516m-.516-.378h.516m-.516-.372h.516m-.516-.378h.516m-.516-.372h.516m-.516-.378h.516m-.516-.372h.516m3.972 2.25h.552m-.552-.378h.552m-.552-.372h.552m-.552-.378h.552m-.552-.372h.552m-.552-.378h.552m-.552-.372h.552"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
