/** @jsxImportSource @emotion/react */
import * as React from "react";
import { Country } from "../entities/Country";
import { toggleCountry } from "../utils/toggle";
import { useSelectedCountriesContext } from "../context/selectedCountries";
import { COLORS } from "../constants/colours";
import { Flag } from "./flags/Flag";
import { css } from "@emotion/react";
import { useHoveredCountriesContext } from "../context/hoveredCountry";
import { BREAKPOINTS } from "../constants/breakpoints";
import IconDeselectBlack from "./icons/IconDeselectBlack";

interface ICountrySelectorProps {
  country: Country;
}

const styles = {
  countryRow: css`
    background-color: ${COLORS.white};
    margin: 1;
    margin: 0 auto 4px auto;
    border-radius: 4px;
    padding: 12px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;
    width: 264px;
  `,
  selectedRow: css`
    background-color: ${COLORS.brandSecondaryRusiGold};
  `,
  flag: css`
    flex-grow: 0;
    flex-shrink: 1;
    align-self: center;
    height: 24px;
  `,
  label: css`
    color: ${COLORS.black},
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 12px;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    text-align: left;
  `,
  deselectWrapper: css`
    height: 20px;
    // margin-right: 6px;
    // @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
    //   margin-right: 0;
    // }
  `,
  deselect: css`
    margin-top: 3px;
  `,
};

const CountrySelector = ({ country }: ICountrySelectorProps) => {
  const { selected, setSelected } = useSelectedCountriesContext();
  const { setHovered } = useHoveredCountriesContext();

  // TODO: Abstract the toggle out elsewhere?
  // const toggle = (country: Country) => {
  //   const index = selected.findIndex((c) => {
  //     return c.name === country.name;
  //   })
  //   if (index >= 0) {
  //     // Remove from the list
  //     const newList = [...selected]
  //     newList.splice(index, 1)
  //     setSelected(newList)
  //   } else {
  //     // Add to the list
  //     const newList = [...selected, country];
  //     setSelected(newList)
  //   }
  // }

  const countryStyle = selected.includes(country)
    ? [styles.countryRow, styles.selectedRow]
    : [styles.countryRow];

  return (
    <div
      onMouseEnter={() => setHovered(country)}
      onMouseLeave={() => setHovered(null)}
      onClick={() => toggleCountry({ selected, setSelected, country })}
      css={countryStyle}
    >
      <div css={styles.flag}>
        <Flag identifier={country.iso} />
      </div>
      <div css={styles.label}>{country.name}</div>
      {selected.includes(country) && (
        <div css={styles.deselectWrapper}>
          <IconDeselectBlack css={styles.deselect} />
        </div>
      )}
    </div>
  );
};
export default CountrySelector;
