import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M0 0h24v24H0V0Z" />
    </mask>
    <g fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
      <path
        fill="#CE0000"
        d="M.305 23.695H18.06L6.44 11.911l12.06.014L.31.305v23.39H.305Z"
      />
      <path
        fill="#000063"
        d="M-.016-.285 19.61 12.25l-12.396-.014 11.62 11.783H.302v-.323h-.319V-.285Zm.647 23.657h16.655L5.665 11.587l11.726.014L.63.896v22.476Z"
      />
      <path
        fill="#fff"
        d="m8.47 17.428-1.265.422.993.928-1.336-.084.549 1.228-1.195-.577.023 1.34-.881-.979-.502 1.247-.431-1.233-.952.966.085-1.299-1.261.535.59-1.172-1.373.028 1.008-.858-1.28-.492 1.266-.422-.999-.928 1.332.085-.544-1.229 1.2.577-.024-1.34.882.979.501-1.247.427 1.233.956-.966-.089 1.299 1.266-.535-.596 1.172 1.378-.028-1.007.858 1.28.492ZM6.952 8.77l-.53.394.262.216a4.397 4.397 0 0 0 1.44-1.688c.084.999-.83 3.235-3.22 3.258a3.308 3.308 0 0 1-3.352-3.295c.468.853.759 1.265 1.5 1.71l.22-.206-.497-.417.642-.169-.347-.58.675.046-.084-.675.59.347.188-.633.422.506.398-.482.216.656.553-.385-.07.67.665-.079-.314.619.643.187Z"
      />
    </g>
  </svg>
);
export default SvgComponent;
