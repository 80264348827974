import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={32}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M0 0h32v24H0V0Z" />
      </mask>
      <g mask="url(#b)">
        <path fill="#C00" d="M0 0h32v24H0V0Z" />
        <path fill="#C00" d="M0 0h32v1.715H0V0Z" />
        <path fill="#fff" d="M0 1.715h32V3.43H0V1.715Z" />
        <path fill="#C00" d="M0 3.43h32v1.715H0V3.43Z" />
        <path fill="#fff" d="M0 5.145h32V6.85H0V5.145Z" />
        <path fill="#C00" d="M0 6.855h32V8.57H0V6.855Z" />
        <path fill="#fff" d="M0 8.57h32v1.715H0V8.57Z" />
        <path fill="#C00" d="M0 10.285h32V12H0v-1.715Z" />
        <path fill="#fff" d="M0 12h32v1.715H0V12Z" />
        <path fill="#C00" d="M0 13.715h32v1.715H0v-1.715Z" />
        <path fill="#fff" d="M0 15.43h32v1.715H0V15.43Z" />
        <path fill="#C00" d="M0 17.145h32v1.704H0v-1.704Z" />
        <path fill="#fff" d="M0 18.855h32v1.715H0v-1.715Z" />
        <path fill="#C00" d="M0 20.57h32v1.715H0V20.57Z" />
        <path fill="#fff" d="M0 22.285h32V24H0v-1.715Z" />
        <path fill="#006" d="M0 .023h16v13.715H0V.023Z" />
        <path
          fill="#FC0"
          d="m10.374 3.691.3 2.035 1.15-1.7-.62 1.96 1.775-1.04-1.405 1.5 2.05-.16-1.915.74 1.915.74-2.05-.16 1.405 1.5-1.775-1.04.615 1.965-1.15-1.705-.3 2.035-.295-2.035-1.15 1.7.62-1.96-1.775 1.04 1.4-1.5-2.05.16 1.92-.74-1.915-.74 2.05.16-1.405-1.5 1.775 1.04-.62-1.965 1.15 1.705.3-2.035Zm-1.665.085a3.555 3.555 0 0 0-4.57 4.947 3.555 3.555 0 0 0 4.57 1.553 4 4 0 0 1-5.746-1.165A4 4 0 0 1 8.71 3.776Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
