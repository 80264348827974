import * as React from "react";
import { SVGProps } from "react";
const FlagAustralia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#00008B" d="M0 0h32v24H0V0Z" />
      <path
        fill="#fff"
        d="m1.875 0 6.1 4.525L14.05 0H16v1.55l-6 4.475 6 4.45V12h-2L8 7.525 2.025 12H0v-1.5l5.975-4.45L0 1.6V0h1.875Z"
      />
      <path
        fill="red"
        d="M10.6 7.025 16 11v1L9.225 7.025H10.6Zm-4.6.5.15.875-4.8 3.6H0l6-4.475ZM16 0v.075l-6.225 4.7.05-1.1L14.75 0H16ZM0 0l5.975 4.4h-1.5L0 1.05V0Z"
      />
      <path fill="#fff" d="M6.025 0v12h4V0h-4ZM0 4v4h16V4H0Z" />
      <path fill="red" d="M0 4.825v2.4h16v-2.4H0ZM6.825 0v12h2.4V0h-2.4Z" />
      <path
        fill="#fff"
        d="m26.35 19.837-1.025.13.11 1.025-.74-.72-.735.725.1-1.025-1.025-.12.865-.56-.545-.875.98.325.345-.975.355.97.975-.335-.535.88.87.555h.005Zm-.185-5.86.135-.65-.49-.45.66-.075.275-.605.275.605.66.075-.49.45.135.65-.58-.33-.58.33Zm-5.205-3-1.015.11.09 1.015-.72-.725-.74.705.12-1.015-1.01-.135.865-.54-.525-.875.965.34.36-.955.335.965.97-.315-.545.865.855.56h-.005Zm10.19-1.64-1.045.135.115 1.045-.755-.735-.75.74.105-1.05-1.045-.12.885-.575-.555-.895 1 .335.35-.99.36.99.995-.345-.55.9.89.565Zm-4.805-4.175-1.035.115.095 1.04-.735-.74-.755.72.12-1.035L23 5.122l.885-.55-.535-.895.985.345.365-.975.34.985.99-.325-.555.88.87.575ZM11.7 19.287l-2.29.27.23 2.295L8 20.232l-1.65 1.61.245-2.295-2.29-.29 1.945-1.24-1.2-1.97 2.18.75.79-2.17.775 2.175 2.185-.735-1.215 1.96 1.94 1.255-.005.005Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FlagAustralia;
