/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { COLORS } from "../constants/colours";
import { BREAKPOINTS } from "../constants/breakpoints";
import { useSelectedCountriesContext } from "../context/selectedCountries";
import CountryTab from "./CountryTab";
import { useHighlightedCountriesContext } from "../context/highlightedCountry";
import IconSelect from "./icons/IconSelect";
import { useModalContext } from "../context/modal";

const styles = {
  root: css`
    background-color: ${COLORS.brandSecondaryRusiRoyalBlue};
  `,
  rootFixed: css`
    background-color: ${COLORS.brandSecondaryRusiRoyalBlue};
    position: fixed;
    top: 0;
    width: 100%;
  `,
  container: css``,
  inner: css`
    margin: 0 auto;
    // max-width: ${BREAKPOINTS.MobileS.container};
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: ${BREAKPOINTS.MobileL.viewport}) {
      padding-left: 0;
      padding-right: 0;
      max-width: ${BREAKPOINTS.MobileL.container};
    }
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      max-width: ${BREAKPOINTS.TabletPortrait.container};
    }
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      max-width: ${BREAKPOINTS.TabletLandscape.container};
    }
    @media (min-width: ${BREAKPOINTS.DesktopHD.viewport}) {
      max-width: ${BREAKPOINTS.DesktopHD.container};
    }
    display: flex;
  `,
  innerLeft: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
  innerRight: css`
    flex-grow: 0;
    flex-shrink: 1;
  `,
  countryTab: css`
    display: flex;
    background-color: ${COLORS.white};
    text-align: left;
    border-right: 1px solid ${COLORS.border};
    padding: 20px 8px;
    &:first-child {
      border-radius: 4px 0 0 0;
      border-left: 0;
    }
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      padding: 20px 24px;
    }
    @media (max-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      flex-shrink: 1;
    }
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      padding: 32px 24px;
    }
  `,
  countryTabHighlighted: css`
    flex-grow: 1;
    flex-shrink: 1;
    @media (max-width: ${BREAKPOINTS.MobileL.viewport}) {
      border-bottom: 4px solid ${COLORS.brandSecondaryRusiGold};
    }
  `,
  countryTabNotHighlighted: css`
    background-color: ${COLORS.brandSecondaryRusiBackground};
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      flex-grow: 0;
    }
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      background-color: ${COLORS.white};
    }
    @media (max-width: ${BREAKPOINTS.MobileL.viewport}) {
      border-bottom: 4px solid ${COLORS.white};
    }
  `,
  countryTabEnd: css`
    border-radius: 0 4px 0 0;
  `,
  addTab: css`
    background-color: ${COLORS.brandSecondaryRusiBackground};
    border-left: 1px solid ${COLORS.border};
    height: 24px;
    line-height: 24px;
    border-radius: 0 4px 0 0;
    padding: 20px 24px;
    display: flex;
    width: 16px;
    gap: 8px;
    cursor: pointer;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      padding: 32px 24px;
      width: 110px;
    }
    @media (max-width: ${BREAKPOINTS.MobileL.viewport}) {
      border-bottom: 4px solid ${COLORS.brandSecondaryRusiBackground};
    }
  `,
  addTabControl: css`
    flex-grow: 0;
    flex-shrink: 1;
    height: 28px;
    line-height: 24px;
    display: flex;
    align-items: center;
  `,
  addTabLabel: css`
    flex-grow: 1;
    flex-shrink: 1;
    display: none;
    height: 24px;
    line-height: 24px;
    color: ${COLORS.brandSecondaryRusiLightBlueTag};
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      display: flex;
    }
  `,
};

interface ICountryTabsProps {
  isSticky: boolean;
}

export const CountryTabs = ({ isSticky }: ICountryTabsProps) => {
  const { selected } = useSelectedCountriesContext();
  const { highlighted } = useHighlightedCountriesContext();
  const { setModal } = useModalContext();

  const dynamicStyles = {
    countriesContainer: css`
      border-radius: 4px 0 0 0;

      @media (max-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
        display: flex;
      }

      @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
        display: grid;
        grid-template-columns: repeat(${selected.length}, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 30px;
      }
    `,
  };

  return (
    <div css={isSticky ? styles.rootFixed : styles.root}>
      <div css={styles.inner}>
        <div css={styles.innerLeft}>
          <div css={dynamicStyles.countriesContainer}>
            {selected.map((selectedCountry, index) => {
              const countryTabStyles =
                selectedCountry === highlighted
                  ? [styles.countryTab, styles.countryTabHighlighted]
                  : [styles.countryTab, styles.countryTabNotHighlighted];

              if (selected.length === 4 && index === 3) {
                countryTabStyles.push(styles.countryTabEnd);
              }

              return (
                <div css={countryTabStyles} key={"tab_" + selectedCountry.iso}>
                  <CountryTab country={selectedCountry} />
                </div>
              );
            })}
          </div>
        </div>
        {selected.length < 4 ? (
          <div css={styles.innerRight}>
            <div css={styles.addTab} onClick={() => setModal(true)}>
              <div css={styles.addTabControl}>
                <IconSelect />
              </div>
              <div css={styles.addTabLabel}>Add country</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
