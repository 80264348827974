/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { COLORS } from "../constants/colours";
import { useSelectedCountriesContext } from "../context/selectedCountries";
import { SubSectionDefinition } from "../entities/SectionDefinition";
import { CountrySubSectionContent } from "./CountrySubSectionContent";
import { BREAKPOINTS } from "../constants/breakpoints";
import { useHighlightedCountriesContext } from "../context/highlightedCountry";
import IconMinus from "./icons/IconMinus";
import IconPlus from "./icons/IconPlus";

const styles = {
  subsection: css``,
  subsectionHeaderWrapper: css`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${COLORS.border};
    padding: 32px 24px;
    gap: 12px;
    cursor: pointer;
  `,
  subsectionControl: css`
    flex-grow: 0;
    flex-shrink: 1;
    color: ${COLORS.brandSecondaryRusiLightBlueTag};
    text-align: center;
    font-family: Font Awesome 6 Free;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
  `,
  subsectionHeader: css`
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
    text-align: left;
    color: ${COLORS.black};
    font-family: Source Sans Pro;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
  inner: css`
    display: flex;
    background-color: ${COLORS.brandSecondaryRusiBackground};
  `,
  innerLeft: css`
    background-color: ${COLORS.white};
    flex-grow: 1;
    flex-shrink: 1;
  `,
  innerRight: css`
    display: none;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      display: flex;
      background-color: ${COLORS.brandSecondaryRusiBackground};
      flex-grow: 0;
      flex-shrink: 1;
    }
  `,
  contentColumn: css`
    padding: 16px;
    text-align: left;
    font-family: Source Serif Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      border-right: 1px solid ${COLORS.border};
    }
  `,
  addTab: css`
    padding: 32px 24px;
    border-radius: 0 4px 0 0;
    width: 16px;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      width: 110px;
    }
  `,
  contentHighlighted: css`
    display: flex;
  `,
  contentNotHighlighted: css`
    display: none;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      display: flex;
    }
  `,
};

interface IContentSubsectionProps {
  definition: SubSectionDefinition;
}
export const ContentSubsection = ({ definition }: IContentSubsectionProps) => {
  const { selected } = useSelectedCountriesContext();
  const { highlighted } = useHighlightedCountriesContext();
  const [open, setOpen] = useState(false);

  const dynamicStyles = {
    contentContainer: css`
      @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
        display: grid;
        grid-template-columns: repeat(${selected.length}, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 30px;
      }
    `,
  };

  const toggle = () => {
    setOpen((current) => !current);
  };

  return (
    <div key={"sub_" + definition.key} css={styles.subsection}>
      <div css={styles.subsectionHeaderWrapper} onClick={toggle}>
        <div css={styles.subsectionControl}>
          {open ? (
            <IconMinus fill={COLORS.brandSecondaryRusiLightBlueTag} />
          ) : (
            <IconPlus fill={COLORS.brandSecondaryRusiLightBlueTag} />
          )}
        </div>
        <div css={styles.subsectionHeader}>{definition.title}</div>
      </div>
      {/* For each country selected, create a content block, fill it if possible. We'll do this flexbox style */}
      {open ? (
        <div css={styles.inner}>
          <div css={styles.innerLeft}>
            <div css={dynamicStyles.contentContainer}>
              {selected.map((selectedCountry) => {
                const contentColumnStyles =
                  selectedCountry === highlighted
                    ? [styles.contentColumn, styles.contentHighlighted]
                    : [styles.contentColumn, styles.contentNotHighlighted];

                return (
                  <div
                    css={contentColumnStyles}
                    key={"blk_" + selectedCountry.iso + "_" + definition.key}
                  >
                    <CountrySubSectionContent
                      definition={definition}
                      country={selectedCountry}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {selected.length < 4 ? (
            <div css={styles.innerRight}>
              <div css={styles.addTab}></div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
