import { createContext, useContext } from "react";
import { SectionDefinition } from "../entities/SectionDefinition";

export type SectionDefinitionData = SectionDefinition[];
export const SectionDefinitionContext = createContext<SectionDefinitionData>(
  [],
);

export const useSectionDefinitionContext = () =>
  useContext(SectionDefinitionContext);
