import React, { useEffect, useState } from "react";
import "./App.css";
import { Country } from "./entities/Country";
import { Route, Routes } from "react-router-dom";
import { HomeScreen } from "./screens/HomeScreen";
import { CompareScreen } from "./screens/CompareScreen";
import { CountriesContext } from "./context/countries";
import { SelectedCountriesContext } from "./context/selectedCountries";
import { Header } from "./components/Header";
import { HoveredCountriesContext } from "./context/hoveredCountry";
import { FeatureCollection } from "geojson";
import { SectionDefinition } from "./entities/SectionDefinition";
import { SectionDefinitionContext } from "./context/sectionDefinition";
import { CountryContentFile } from "./entities/CountryContentFile";
import { HighlightedCountryContext } from "./context/highlightedCountry";
import { ModalContext } from "./context/modal";
import { PartnershipBanner } from "./components/PartnershipBanner";

function App() {
  const [sectionDefinitions, setSectionDefinitions] = useState<
    SectionDefinition[]
  >([]);
  const [countriesData, setCountriesData] = useState<Country[]>([]);
  const [selected, setSelected] = useState<Country[]>([]);
  const [hovered, setHovered] = useState<Country | null>(null);
  const [highlighted, setHighlighted] = useState<Country | null>(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetch("countries.json")
      .then((response) => response.json())
      .then((countriesData) => {
        let rawCountries: Country[] = countriesData.countries;
        const rawSectionDefinitions: SectionDefinition[] =
          countriesData.sections;

        let sortedCountries = rawCountries.sort((a, b) =>
          a.name.localeCompare(b.name),
        );

        setSectionDefinitions(rawSectionDefinitions);

        fetch("geometry_110m.geojson")
          .then((response) => response.json())
          .then((data) => {
            const featureCollection = data as FeatureCollection;

            // For each of our countries, find a relevant feature
            sortedCountries = sortedCountries.map((country) => {
              const matchingFeature = featureCollection.features.find(
                // @ts-ignore
                (f) => f.properties["ADM0_A3"] === country.iso.toUpperCase(),
              );
              if (matchingFeature?.geometry) {
                country.geometry = matchingFeature.geometry;
              }

              return country;
            });

            // Now lets preload all the comparison content
            Promise.all(
              rawCountries.map((country) =>
                fetch(`content/${country.iso.toLowerCase()}.json`),
              ),
            )
              .then((results) => Promise.all(results.map((r) => r.json())))
              .then((results: CountryContentFile[]) => {
                const hydratedCountries = sortedCountries.map((rawCountry) => {
                  // See if there is any content for this country
                  const data = results.find(
                    (result) =>
                      result.iso.toLowerCase() === rawCountry.iso.toLowerCase(),
                  );
                  if (data) {
                    rawCountry.content = data.content;
                    rawCountry.papers = data.papers;
                  }

                  return rawCountry;
                });

                // Set the country data
                setCountriesData(hydratedCountries);
              });
          });
      });
  }, []);

  return (
    <div className="App">
      <SectionDefinitionContext.Provider value={sectionDefinitions}>
        <CountriesContext.Provider value={countriesData}>
          <SelectedCountriesContext.Provider value={{ selected, setSelected }}>
            <HoveredCountriesContext.Provider value={{ hovered, setHovered }}>
              <HighlightedCountryContext.Provider
                value={{ highlighted, setHighlighted }}
              >
                <ModalContext.Provider value={{ modal, setModal }}>
                  <PartnershipBanner />
                  <Header />
                  <Routes>
                    <Route path="/" element={<HomeScreen />} />
                    <Route path="/compare" element={<CompareScreen />} />
                  </Routes>
                </ModalContext.Provider>
              </HighlightedCountryContext.Provider>
            </HoveredCountriesContext.Provider>
          </SelectedCountriesContext.Provider>
        </CountriesContext.Provider>
      </SectionDefinitionContext.Provider>
    </div>
  );
}

export default App;
