/** @jsxImportSource @emotion/react */
import { Country } from "../entities/Country";
import CountrySelector from "./CountrySelector";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "../constants/breakpoints";

interface ICountrySelectorListProps {
  countries: Country[];
}

const styles = {
  list: css`
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 4px;
    }
  `,
};

const CountrySelectorGrid = ({ countries }: ICountrySelectorListProps) => {
  return (
    <div css={styles.list}>
      {countries.map((country) => (
        <CountrySelector country={country} key={country.iso} />
      ))}
    </div>
  );
};
export default CountrySelectorGrid;
