import { Country } from "../entities/Country";
import { createContext, useContext } from "react";

export type CountriesSelectedState = {
  selected: Country[];
  setSelected: (c: Country[]) => void;
};

export const SelectedCountriesContext = createContext<CountriesSelectedState>({
  selected: [],
  setSelected: () => {},
});
export const useSelectedCountriesContext = () =>
  useContext(SelectedCountriesContext);
