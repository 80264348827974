/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { COLORS } from "../constants/colours";
import CountrySelectorGrid from "./CountrySelectorGrid";
import { useCountriesContext } from "../context/countries";
import { BREAKPOINTS } from "../constants/breakpoints";
import IconCross from "./icons/IconCross";
import { useModalContext } from "../context/modal";

const styles = {
  background: css`
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    align-items: center;
    justify-content: space-around;
  `,
  modal: css`
    position: relative;
    z-index: 200;
    display: inline-flex;
    padding-bottom: 0px;
    flex-direction: column;
    gap: 24px;
    background-color: ${COLORS.brandSecondaryRusiBackground};
    opacity: 1;

    border-radius: 8px;
    background: ${COLORS.brandSecondaryRusiBackground};
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  `,
  topBar: css`
    border: 1px solid ${COLORS.brandSecondaryRusiBackground};
    background-color: ${COLORS.white};
    padding: 12px 24px;
    opacity: 1;
    display: flex;
  `,
  title: css`
    color: ${COLORS.brandSecondaryRusiLightBlueTag};
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 1px;
    text-transform: uppercase;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: left;
  `,
  close: css`
    color: ${COLORS.brandSecondaryRusiLightBlueTag};
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 1px;
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    gap: 4px;
    cursor: pointer;
  `,
  closeControl: css`
    height: 24px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    align-items: center;
  `,
  closeLabel: css`
    height: 24px;
    line-height: 24px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
  `,
  content: css`
    background-color: ${COLORS.brandSecondaryRusiBackground};
    padding: 0 24px 24px 24px;
    opacity: 1;
    max-height: 80vh;
    overflow-y: scroll;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      overflow-y: auto;
    }
  `,
};

export const CountrySelectorModal = () => {
  const countries = useCountriesContext();
  const { setModal } = useModalContext();

  return (
    <div css={styles.background}>
      <div css={styles.modal}>
        <div css={styles.topBar}>
          <div css={styles.title}>Add Country</div>
          <div css={styles.close} onClick={() => setModal(false)}>
            <div css={styles.closeControl}>
              <IconCross
                fill={COLORS.brandSecondaryRusiLightBlueTag}
                width="14px"
                height="14px"
              />
            </div>
            <div css={styles.closeLabel}>Close</div>
          </div>
        </div>
        <div css={styles.content}>
          <CountrySelectorGrid countries={countries} />
        </div>
      </div>
    </div>
  );
};
