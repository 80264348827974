import { Renderer } from "@ts-stack/markdown";

export class CustomRenderer extends Renderer {
  // Overriding parent method.

  override paragraph(text: string): string {
    return text;
  }

  override link(href: string, title: string, text: string): string {
    if (this.options.sanitize) {
      let prot: string;

      try {
        // @ts-ignore
        prot = decodeURIComponent(this.options.unescape(href))
          .replace(/[^\w:]/g, "")
          .toLowerCase();
      } catch (e) {
        return text;
      }

      if (
        // eslint-disable-next-line no-script-url
        prot.indexOf("javascript:") === 0 ||
        prot.indexOf("vbscript:") === 0 ||
        prot.indexOf("data:") === 0
      ) {
        return text;
      }
    }

    let out = '<a href="' + href + '"';

    if (title) {
      out += ' title="' + title + '"';
    }

    out += ' target="_blank"';

    out += ">" + text + "</a>";

    return out;
  }
}
