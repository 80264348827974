interface IBreakpoint {
  viewport: string;
  container: string;
}

interface IBreakpoints {
  MobileS: IBreakpoint;
  MobileL: IBreakpoint;
  TabletPortrait: IBreakpoint;
  TabletLandscape: IBreakpoint;
  DesktopHD: IBreakpoint;
}

export const BREAKPOINTS: IBreakpoints = {
  MobileS: {
    viewport: "320px",
    container: "320px", //"288px",
  },
  MobileL: {
    viewport: "414px",
    container: "368px",
  },
  TabletPortrait: {
    viewport: "768px",
    container: "728px",
  },
  TabletLandscape: {
    viewport: "1024px",
    container: "992px",
  },
  DesktopHD: {
    viewport: "1200px",
    container: "1168px",
  },
};
