import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#fff" d="M0-.5h32v24H0" />
      <path
        fill="#CE1126"
        d="M32-.5H4.8l5.536 2.4L4.8 4.3l5.535 2.4L4.8 9.1l5.535 2.4L4.8 13.9l5.535 2.4L4.8 18.7l5.535 2.4L4.8 23.5H32"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0-.5h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
