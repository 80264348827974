/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { SubSectionDefinition } from "../entities/SectionDefinition";
import { Country } from "../entities/Country";
import { COLORS } from "../constants/colours";
import { Marked } from "@ts-stack/markdown";
import { CustomRenderer } from "../utils/customRenderer";

interface ICountrySubSectionContentProps {
  definition: SubSectionDefinition;
  country: Country;
}

const styles = {
  p: css`
    color: ${COLORS.black};
    font-family: Source Serif Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  `,
};

export const CountrySubSectionContent = ({
  definition,
  country,
}: ICountrySubSectionContentProps) => {
  // Find any content blocks
  const blocks = country.content?.find(
    (content) => content.section === definition.key,
  );

  Marked.setOptions({
    renderer: new CustomRenderer(),
    gfm: true,
    tables: true,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: true,
  });

  if (!blocks || blocks?.content.length < 1) {
    return <div>No content</div>;
  }

  return (
    <div>
      {blocks.content.map((block, idx) => {
        switch (block.type) {
          case "p":
          default:
            return (
              <p
                key={"blk_" + country.iso + "_" + definition.key + "_" + idx}
                css={styles.p}
                dangerouslySetInnerHTML={{
                  __html: Marked.parse(block.content),
                }}
              ></p>
            );
        }
      })}
    </div>
  );
};
