import { createContext, useContext } from "react";

export type ModalState = {
  modal: boolean;
  setModal: (c: boolean) => void;
};

export const ModalContext = createContext<ModalState>({
  modal: false,
  setModal: () => {},
});
export const useModalContext = () => useContext(ModalContext);
