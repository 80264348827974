/** @jsxImportSource @emotion/react */
import { COLORS } from "../constants/colours";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "../constants/breakpoints";

export const PartnershipBanner = () => {
  const styles = {
    container: css`
      align-items: center;
      gap: 10px;
      background-color: ${COLORS.white};
      padding: 17px 0 20px 0;
    `,
    preText: css`
      color: ${COLORS.brandSecondaryRusiRoyalBlue};
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 20px;
    `,
    inner: css`
      border-width: 1;
      margin: 0 auto;
      display: flex;

      gap: 13px;
      flex-direction: column;
      align-items: center;

      @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
        gap: 20px;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        max-width: ${BREAKPOINTS.TabletLandscape.container};
      }
      @media (min-width: ${BREAKPOINTS.DesktopHD.viewport}) {
        max-width: ${BREAKPOINTS.DesktopHD.container};
      }
    `,
    logo: css`
      flex-shrink: 1;
      display: flex;
      align-items: center;
    `,
  };

  return (
    <div css={styles.container}>
      <div css={styles.preText}>A partnership between</div>
      <div css={styles.inner}>
        <div css={styles.logo}>
          <img src="images/rusi-logo.png" alt="RUSI" height="36px" />
        </div>
        <div css={styles.logo}>
          <img src="images/siia-logo.jpg" alt="SIIA" height="36px" />
        </div>
      </div>
    </div>
  );
};
