/** @jsxImportSource @emotion/react */
import { COLORS } from "../constants/colours";
import { COMMON_STYLES } from "../constants/styles";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "../constants/breakpoints";

export const Header = () => {
  const styles = {
    header: css`
      align-items: center;
      gap: 10px;
      background-color: ${COLORS.brandSecondaryRusiRoyalBlue};
      padding: 48px 24px;
      @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
        padding: 48px 136px;
      }
    `,
    inner: css`
      margin: 0 auto;
      @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
        max-width: ${BREAKPOINTS.TabletLandscape.container};
      }
      @media (min-width: ${BREAKPOINTS.DesktopHD.viewport}) {
        max-width: ${BREAKPOINTS.DesktopHD.container};
      }
    `,
  };

  return (
    <div css={styles.header}>
      <div css={styles.inner}>
        <h1 css={COMMON_STYLES.h1}>Lorem Dolor Det amet Dolor</h1>
        <h2 css={COMMON_STYLES.h2}>
          Donec tempus nisi consequat feugiat ornare. Maecenas blandit, enim nec
          tempus suscipit, sem dolor blandit diam, in hendrerit massa mauris at
          urna.
        </h2>
      </div>
    </div>
  );
};
