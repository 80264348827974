import { Country } from "../entities/Country";
import { CountriesSelectedState } from "../context/selectedCountries";

interface IToggleCountryProps extends CountriesSelectedState {
  country: Country;
}

export const MAX_SELECTIONS = 4;

export const toggleCountry = ({
  selected,
  setSelected,
  country,
}: IToggleCountryProps) => {
  const index = selected.findIndex((c) => {
    return c.name === country.name;
  });
  if (index >= 0) {
    // Remove from the list
    const newList = [...selected];
    newList.splice(index, 1);
    setSelected(newList);
  } else {
    // Ensure we're not at the limit
    if (selected.length < MAX_SELECTIONS) {
      // Add to the list
      const newList = [...selected, country];
      setSelected(newList);
    }
  }
};
