import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#0038A8" d="M0 0h32v12H0V0Z" />
      <path fill="#CE1126" d="M0 12h32v12H0V12Z" />
      <path fill="#fff" d="M20.785 12 0 24V0" />
      <path
        fill="#FCD116"
        d="m1.334 2.121.715.63.83-.46-.37.875.7.65-.95-.08-.405.86-.215-.925-.94-.12.815-.5-.18-.93Zm16.19 8.615.02.95.9.315-.9.31-.02.95-.575-.755-.91.275.54-.78-.54-.78.91.275.575-.76ZM1.86 19.406l.4.86.95-.08-.695.65.37.875-.83-.455-.72.62.18-.935-.815-.49.945-.12.215-.925Zm5.71-12.45-.31.31.155 2.35-.15.015-.285-2.145-.255.25.38 1.92c-.307.06-.6.181-.86.355L5.16 8.391H4.8l1.32 1.715a2.402 2.402 0 0 0-.12.1L4.444 8.431h-.44v.44l1.77 1.55-.1.125L3.96 9.231v.355l1.625 1.085c-.17.26-.295.55-.355.86l-1.915-.38-.255.25 2.145.285a.814.814 0 0 0-.015.155l-2.35-.15-.31.31.31.31 2.35-.155.015.155-2.145.285.25.25 1.92-.38c.06.307.181.6.355.86L3.96 14.411v.36l1.715-1.315c.032.041.066.081.1.12l-1.77 1.55v.44h.445L6 13.796l.125.1L4.8 15.611h.36l1.085-1.625c.255.17.55.295.86.355l-.38 1.92.25.25.285-2.145c.05.01.1.015.155.015l-.15 2.35.305.31.315-.31-.155-2.35.15-.015.285 2.15.255-.255-.38-1.92c.307-.06.6-.181.86-.355l1.085 1.625h.36l-1.32-1.715c.041-.032.081-.065.12-.1l1.555 1.77h.44v-.44l-1.77-1.55.1-.12 1.715 1.315v-.36L9.56 13.326c.17-.255.295-.55.355-.86l1.915.38.255-.25-2.145-.285c.01-.05.015-.1.015-.155l2.35.15.31-.305-.31-.31-2.35.15-.015-.15 2.145-.285-.25-.25-1.92.375c-.06-.307-.18-.6-.355-.86l1.625-1.085v-.355L9.47 10.546a2.383 2.383 0 0 0-.1-.12l1.77-1.55v-.445h-.44l-1.55 1.775a2.387 2.387 0 0 0-.125-.1l1.32-1.715h-.36L8.9 10.011a2.375 2.375 0 0 0-.86-.355l.38-1.915-.25-.25-.285 2.14-.155-.015.15-2.35-.31-.31Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
