import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#006A4E" d="M0-.25h32v24H0v-24Z" />
      <path fill="#F42A41" d="M14 19.75a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0-.25h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
