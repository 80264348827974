/** @jsxImportSource @emotion/react */
import { COLORS } from "./colours";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "./breakpoints";

export const COMMON_STYLES = {
  h1: css`
    color: ${COLORS.white};
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    padding: 0;
    margin: 0 0 16px 0;
    font-size: 32px;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      font-size: 48px;
    }
  `,
  h2: css`
    color: ${COLORS.white};
    font-family: Source Serif Pro;
    font-weight: 600;
    padding: 0;
    margin: 0;
    font-size: 22px;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      font-size: 24px;
    }
  `,
};
