import * as React from "react";
import { SVGProps } from "react";
const FlagUnitedKingdom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#012169" d="M0 0h32v24H0V0Z" />
      <path
        fill="#fff"
        d="m3.75 0 12.2 9.05L28.1 0H32v3.1l-12 8.95 12 8.9V24h-4l-12-8.95L4.05 24H0v-3l11.95-8.9L0 3.2V0h3.75Z"
      />
      <path
        fill="#C8102E"
        d="M21.2 14.05 32 22v2l-13.55-9.95h2.75Zm-9.2 1 .3 1.75L2.7 24H0l12-8.95ZM32 0v.15l-12.45 9.4.1-2.2L29.5 0H32ZM0 0l11.95 8.8h-3L0 2.1V0Z"
      />
      <path fill="#fff" d="M12.05 0v24h8V0h-8ZM0 8v8h32V8H0Z" />
      <path fill="#C8102E" d="M0 9.65v4.8h32v-4.8H0ZM13.65 0v24h4.8V0h-4.8Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FlagUnitedKingdom;
