/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useSectionDefinitionContext } from "../context/sectionDefinition";
import { COLORS } from "../constants/colours";
import { CountryTabs } from "../components/CountryTabs";
import { BREAKPOINTS } from "../constants/breakpoints";
import { ContentSubsection } from "../components/ContentSubsection";
import { useSelectedCountriesContext } from "../context/selectedCountries";
import { useHighlightedCountriesContext } from "../context/highlightedCountry";
import { CountrySelectorModal } from "../components/CountrySelectorModal";
import { useModalContext } from "../context/modal";
import IconDeselect from "../components/icons/IconDeselect";
import { toggleCountry } from "../utils/toggle";
import { StickyContainer, Sticky } from "react-sticky";
import { AccompanyingPapers } from "../components/AccompanyingPapers";

const styles = {
  screen: css`
    background-color: ${COLORS.white};
    height: 100%;
  `,
  container: css`
    margin: 24px auto;
    // max-width: ${BREAKPOINTS.MobileS.container};
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: ${BREAKPOINTS.MobileL.viewport}) {
      padding-left: 0;
      padding-right: 0;
      max-width: ${BREAKPOINTS.MobileL.container};
    }
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      max-width: ${BREAKPOINTS.TabletPortrait.container};
    }
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      max-width: ${BREAKPOINTS.TabletLandscape.container};
    }
    @media (min-width: ${BREAKPOINTS.DesktopHD.viewport}) {
      max-width: ${BREAKPOINTS.DesktopHD.container};
    }
  `,
  section: css``,
  sectionHeader: css`
    margin: 0;
    text-align: left;
    padding: 24px;
    color: #287d9f;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid ${COLORS.border};
  `,
  remove: css`
    margin: 16px 16px 0 0;
    display: flex;
    gap: 0;
    flex-direction: row-reverse;
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      display: none;
    }
  `,
  removeControl: css`
    display: flex;
    align-content: center;
    flex-grow: 0;
    flex-shrink: 1;
    height: 16px;
  `,
  removeLabel: css`
    display: flex;
    align-content: center;
    flex-grow: 0;
    flex-shrink: 1;
    color: ${COLORS.brandSecondaryRusiLightBlueTag};
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    height: 16px;
    padding-left: 4px;
  `,
};

export const CompareScreen = () => {
  const nav = useNavigate();
  const sectionDefinitions = useSectionDefinitionContext();
  const { selected, setSelected } = useSelectedCountriesContext();
  const { highlighted, setHighlighted } = useHighlightedCountriesContext();
  const { modal } = useModalContext();
  const screenRef = useRef(null);

  const scrollToStart = () => {
    // @ts-ignore
    screenRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // If no selected countries, return to map view
  useEffect(() => {
    if (selected.length === 0) {
      nav("/");
    }
  }, [nav, selected]);

  // When highlighted changes, we need to check that it is valid
  useEffect(() => {
    if (!highlighted || !selected.includes(highlighted)) {
      setHighlighted(selected[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlighted, selected]);

  // On first load, set highlighted to the first selected country
  // On first load, scroll to content
  useEffect(() => {
    if (!highlighted || !selected.includes(highlighted)) {
      setHighlighted(selected[0]);
    }
    scrollToStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StickyContainer>
      <div css={styles.screen} ref={screenRef}>
        <Sticky>
          {({
            style,

            // the following are also available but unused in this example
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight,
          }) => (
            <div>
              <CountryTabs isSticky={isSticky} />
            </div>
          )}
        </Sticky>
        {highlighted ? (
          <div css={styles.remove}>
            <div
              css={styles.removeLabel}
              onClick={() =>
                toggleCountry({ selected, setSelected, country: highlighted })
              }
            >
              Remove
            </div>
            <div css={styles.removeControl}>
              <IconDeselect fill={COLORS.brandSecondaryRusiLightBlueTag} />
            </div>
          </div>
        ) : null}
        <div css={styles.container}>
          {sectionDefinitions.map((section) => {
            return (
              <div key={section.title} css={styles.section}>
                <h1 css={styles.sectionHeader}>{section.title}</h1>
                {section.subsections.map((subsection) => {
                  return (
                    <ContentSubsection
                      definition={subsection}
                      key={"sub_" + subsection.key}
                    />
                  );
                })}
              </div>
            );
          })}
          <div>
            <h1 css={styles.sectionHeader}>Accompanying Papers</h1>
            <AccompanyingPapers />
          </div>
        </div>
        {modal ? <CountrySelectorModal /> : null}
      </div>
    </StickyContainer>
  );
};
