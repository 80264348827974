import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#FECB00" d="M0 0h32v24H0V0Z" />
      <path fill="#34B233" d="M0 8h32v16H0V8Z" />
      <path fill="#EA2839" d="M0 16h32v8H0v-8Z" />
      <path fill="#fff" d="m16 4 2.654 8.844h-5.306L16 4Z" />
      <path fill="#fff" d="m10.803 20 3.052-8.715 4.293 3.119-7.345 5.595Z" />
      <path fill="#fff" d="m7.59 10.11 9.23.21-1.64 5.046-7.59-5.256Z" />
      <path fill="#fff" d="m24.414 10.11-7.592 5.256-1.64-5.047 9.232-.21Z" />
      <path
        fill="#fff"
        d="m21.197 19.997-7.345-5.595 4.293-3.12 3.052 8.715Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
