import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={32}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M0 0h32v24H0V0Z" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M-2 0h36v24H-2V0Z"
          clipRule="evenodd"
        />
        <path
          fill="#BC002D"
          d="M16.003 19.46a7.46 7.46 0 1 0 0-14.92 7.46 7.46 0 0 0 0 14.92Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
