import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#EE1C25" d="M0 0h32v24H0V0Z" />
      <path
        fill="#FF0"
        d="m3.838 8.878 2.16-6.48 2.16 6.48-5.76-3.96h7.2l-5.76 3.96ZM13.208 2.525l-2.223.493 1.482-1.728-.145 2.325-1.233-2.059 2.119.969ZM15.454 5.369l-2.24-.409 2.036-1.017-1.036 2.087-.338-2.376 1.578 1.715ZM15.127 9.355l-1.88-1.286 2.276-.098-1.797 1.483.66-2.308.74 2.21ZM12.299 11.963l-1.236-1.911 2.136.787-2.23.674 1.5-1.873-.17 2.323Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
