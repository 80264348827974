/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { COLORS } from "../constants/colours";
import { useSelectedCountriesContext } from "../context/selectedCountries";
import { BREAKPOINTS } from "../constants/breakpoints";
import { useHighlightedCountriesContext } from "../context/highlightedCountry";
import { Country } from "../entities/Country";
import { Marked } from "@ts-stack/markdown";
import { CustomRenderer } from "../utils/customRenderer";

const styles = {
  subsection: css``,
  subsectionHeaderWrapper: css`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${COLORS.border};
    padding: 32px 24px;
    gap: 12px;
    cursor: pointer;
  `,
  subsectionControl: css`
    flex-grow: 0;
    flex-shrink: 1;
    color: ${COLORS.brandSecondaryRusiLightBlueTag};
    text-align: center;
    font-family: Font Awesome 6 Free;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
  `,
  subsectionHeader: css`
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
    text-align: left;
    color: ${COLORS.black};
    font-family: Source Sans Pro;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
  inner: css`
    display: flex;
    background-color: ${COLORS.brandSecondaryRusiBackground};
  `,
  innerLeft: css`
    background-color: ${COLORS.white};
    flex-grow: 1;
    flex-shrink: 1;
  `,
  innerRight: css`
    display: none;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      display: flex;
      background-color: ${COLORS.brandSecondaryRusiBackground};
      flex-grow: 0;
      flex-shrink: 1;
    }
  `,
  contentColumn: css`
    flex-direction: column;
    padding: 16px;
    text-align: left;
    font-family: Source Serif Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      border-right: 1px solid ${COLORS.border};
    }
  `,
  addTab: css`
    padding: 32px 24px;
    border-radius: 0 4px 0 0;
    width: 16px;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      width: 110px;
    }
  `,
  contentHighlighted: css`
    display: flex;
  `,
  contentNotHighlighted: css`
    display: none;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      display: flex;
    }
  `,
  paper: css`
    margin: 5px 0;
  `,
};

export const AccompanyingPapers = () => {
  const { selected } = useSelectedCountriesContext();
  const { highlighted } = useHighlightedCountriesContext();

  Marked.setOptions({
    renderer: new CustomRenderer(),
    gfm: true,
    tables: true,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: true,
  });

  const dynamicStyles = {
    contentContainer: css`
      @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
        display: grid;
        grid-template-columns: repeat(${selected.length}, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 30px;
      }
    `,
  };

  return (
    <div css={styles.inner}>
      <div css={styles.innerLeft}>
        <div css={dynamicStyles.contentContainer}>
          {selected.map((selectedCountry: Country) => {
            const contentColumnStyles =
              selectedCountry === highlighted
                ? [styles.contentColumn, styles.contentHighlighted]
                : [styles.contentColumn, styles.contentNotHighlighted];
            return (
              <div
                css={contentColumnStyles}
                key={"blk_" + selectedCountry.iso + "_papers"}
              >
                {selectedCountry?.papers?.map((paper) => {
                  return (
                    <p
                      css={styles.paper}
                      dangerouslySetInnerHTML={{
                        __html: Marked.parse(paper),
                      }}
                    ></p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {selected.length < 4 ? (
        <div css={styles.innerRight}>
          <div css={styles.addTab}></div>
        </div>
      ) : null}
    </div>
  );
};
