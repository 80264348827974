/** @jsxImportSource @emotion/react */
import React from "react";
import { useCountriesContext } from "../context/countries";
import {
  GeoJSON,
  LayerGroup,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { COLORS } from "../constants/colours";
import { css } from "@emotion/react";
import { hoverIcon, normalIcon } from "../utils/icons";
import { useHoveredCountriesContext } from "../context/hoveredCountry";
import { PopupComponent } from "../components/PopupComponent";
import { BREAKPOINTS } from "../constants/breakpoints";
import { MapControls } from "../components/MapControls";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useSelectedCountriesContext } from "../context/selectedCountries";

const styles = {
  root: css`
    background-color: ${COLORS.brandSecondaryRusiRoyalBlue};
    position: relative;
  `,
  container: css`
    position: relative;
    margin: 0 auto;
    max-width: ${BREAKPOINTS.MobileS.container};
    @media (min-width: ${BREAKPOINTS.MobileL.viewport}) {
      max-width: ${BREAKPOINTS.MobileL.container};
    }
    @media (min-width: ${BREAKPOINTS.TabletPortrait.viewport}) {
      max-width: ${BREAKPOINTS.TabletPortrait.container};
    }
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      max-width: ${BREAKPOINTS.TabletLandscape.container};
    }
    @media (min-width: ${BREAKPOINTS.DesktopHD.viewport}) {
      max-width: ${BREAKPOINTS.DesktopHD.container};
    }
  `,
  map: css`
    display: none;
    @media (min-width: ${BREAKPOINTS.TabletLandscape.viewport}) {
      display: flex;
      padding: 48px 136px;
      align-items: center;
      gap: 10px;
      height: 700px;
    }
  `,
};

export const HomeScreen = () => {
  const countries = useCountriesContext();
  const { hovered, setHovered } = useHoveredCountriesContext();
  const { width } = useWindowDimensions();
  const { selected } = useSelectedCountriesContext();

  return (
    <div css={styles.root}>
      <div css={styles.container}>
        <MapControls />
      </div>
      {width >= 1024 ? (
        <MapContainer
          center={[10, 100]}
          zoom={3}
          zoomControl={false}
          scrollWheelZoom={false}
          css={styles.map}
        >
          <TileLayer
            attribution="Source: NASA Blue Marble, image service by OpenGeo"
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//GoogleMapsCompatible_Level8/{z}/{y}/{x}.jpeg"
          />
          <ZoomControl position="bottomright" />
          <LayerGroup>
            {countries.map((country) => {
              if (country.geometry) {
                const highlight = hovered?.iso === country.iso;
                return (
                  <GeoJSON
                    key={"geojson_" + country.iso}
                    data={country.geometry}
                    pathOptions={{
                      opacity:
                        highlight || selected.includes(country) ? 100 : 0,
                      fillOpacity:
                        highlight || selected.includes(country) ? 100 : 0,
                      color: COLORS.brandSecondaryRusiGold,
                    }}
                    eventHandlers={{
                      mouseover: () => {
                        setHovered(country);
                      },
                      mouseout: () => {
                        setHovered(null);
                      },
                    }}
                  >
                    <Popup offset={[20, 30]} closeButton={false}>
                      <PopupComponent country={country} />
                    </Popup>
                  </GeoJSON>
                );
              } else {
                console.log("No geo data: ", country.iso);
                return null;
              }
            })}
          </LayerGroup>
          <LayerGroup>
            {countries.map((country) => {
              if (!country.location) {
                return null;
              }
              return (
                <Marker
                  key={"marker_" + country.iso}
                  position={country.location}
                  icon={hovered === country ? hoverIcon : normalIcon}
                />
              );
            })}
          </LayerGroup>
        </MapContainer>
      ) : null}
    </div>
  );
};
