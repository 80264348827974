import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={32}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M0 0h32v24H0V0Z" />
      </mask>
      <g fillRule="evenodd" clipRule="evenodd" mask="url(#b)">
        <path fill="#CE1126" d="M-2 0h36v24H-2V0Z" />
        <path fill="#002868" d="M-2 5.965h36v12.07H-2V5.965Z" />
        <path
          fill="#fff"
          d="M21.168 11.998a5.17 5.17 0 1 1-10.34 0 5.17 5.17 0 0 1 10.34 0Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
